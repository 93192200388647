<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="formData" :model="formData" label-width="80px">
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">当前：盘点设置</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="2" :sm="12" :md="8">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini"
                                           @click="dialogFormVisible = true">新增添加
                                </el-button>
                            </el-col>
                            <el-col :lg="2">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; "  @click="deleteEvent" type="primary" size="mini">
                                    批量删除
                                </el-button>
                            </el-col>
                            <el-col :lg="2">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" @click="setIsOver(true)" size="mini">结转
                                </el-button>
                            </el-col>
                            <el-col :lg="2">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" @click="setIsOver(false)" size="mini">
                                    反结转
                                </el-button>
                            </el-col>
                            <el-col :lg="2">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="warning" @click="setIsLock(true)" size="mini">锁定
                                </el-button>
                            </el-col>
                            <el-col :lg="2">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="warning" @click="setIsLock(false)" size="mini">
                                    反锁定
                                </el-button>
                            </el-col>
                            <el-dialog width="420px" title="盘点设置" :visible.sync="dialogFormVisible">
                                <el-form :model="formData">
                                    <el-form-item label="盘点月份:">
                                        <el-date-picker
                                                v-model="formData.year_month"
                                                @change="changeyearMonth"
                                                value-format="yyyy-MM-dd"
                                                align="right"
                                                type="month"
                                                size="mini"
                                                placeholder="选择日期">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="起始日期:">
                                        <el-date-picker
                                                v-model="formData.start_date"
                                                :readonly="true"
                                                align="right"
                                                value-format="yyyy-MM-dd"
                                                type="date"
                                                size="mini"
                                                placeholder="自动关联">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="结束日期:">
                                        <el-date-picker
                                                v-model="formData.end_date"
                                                :readonly="true"
                                                align="right"
                                                value-format="yyyy-MM-dd"
                                                type="date"
                                                size="mini"
                                                placeholder="自动关联">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="状态:">
                                        <el-radio-group v-model="formData.is_over" size="small">
                                            <el-radio :label="false" border>未结转</el-radio>
                                            <el-radio :label="true" border>已结转</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-form>
                                <div slot="footer" class="dialog-footer">
                                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                                    <el-button type="primary" @click="saveOrUpdateInventory">确 定</el-button>
                                </div>
                            </el-dialog>

                        </el-row>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <!--checkbox-config 选择框范围选择 -->
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source

                                ref="xGrid"
                                :pager-config="pagerConfig"
                                :data="tableData"
                                :columns="tableColumn"
                                :height="(this.getViewHeight() - 130)+''"
                                @page-change="handlePageChange"
                                highlight-current-row
                                :checkbox-config="{ highlight: true, range: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:false}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,5000,10000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>


                </el-row>

            </el-form>
        </el-main>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'


    export default {

        data() {
            return {
                cheackIds: [],
                pagerConfig: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 10,
                    pageSizes: [10, 20, 50, 100, 200, 500]
                },
                tableColumn: [
                    {type: 'checkbox', field:'id', width: 60},
                    {field:'year_month', width: 100, title: '盘点月份', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy年MM月')
                       }
                   },
                    {field:'start_date', width: 100, title: '起始日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }
                   },
                    {field:'end_date', width: 100, title: '结束日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }
                   },
                    {field:'is_over', width: 100, title: '备注', formatter: ({cellValue}) => {
                            if (cellValue == true){
                                return '已结转'
                           }else if (cellValue == false){
                                return '未结转'
                           }else{
                                return '未知异常'
                           }
                       }
                   },
                    {field:'is_lock', width: 100, title: '财务锁定', formatter: ({cellValue}) => {
                            if (cellValue == true){
                                return '已锁定'
                            }else if (cellValue == false){
                                return '未锁定'
                            }else{
                                return '未知异常'
                            }
                        }
                    },
                ],
                tableData: [],
                dialogFormVisible: false,
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                       }
                   }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                       }
                   }]
               },
                filterData: {},
                formData: {
                    id: '',
                    year_month : '',
                    start_date: '',
                    end_date: '',
                    is_over:false,
               },
                activeName: 'first',
                checked: false,
                regionList: [],
           }
       },
        methods: {
            search() {
                this.$axios({
                    method: 'post',
                    url: '/admin/inventory/search',
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                   },
               }).then((response) => {          //这里使用了ES6的语法
                    console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.tableData = response.data.page.list;
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            changeyearMonth() {
                //console.log('changeyearMonth' + this.year_month)
                if (this.isBlankVue(this.formData.year_month)){
                    this.formData.start_date = '';
                    this.formData.end_date = '';
               }else{
                    let date = XEUtils.toDateString(this.formData.year_month, 'yyyy-MM-dd')
                    this.formData.start_date = date.substring(0, 8) + '01'
                    this.formData.end_date = date.substring(0, 8) + XEUtils.getDayOfMonth(this.formData.year_month)
               }

                //console.log('this.startDate' + this.startDate)
                //console.log('this.endDate' + this.endDate)
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.search()
           },
            editRowEvent(row) {
                const $grid = this.$refs.xGrid
                $grid.setActiveRow(row)
           },
            saveOrUpdateInventory(){
                this.$axios({
                    method: 'post',
                    url: '/admin/inventory/saveOrUpdateInventory',
                    data: {
                        formData: this.formData,
                   },
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                       });
                        this.dialogFormVisible = false
                        this.search();
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            setIsOver(b){
                const $grid = this.$refs.xGrid
                const selectRecords = $grid.getCheckboxRecords();
                this.cheackIds = [];
                for (let i = 0; i < selectRecords.length; i++) {
                    this.cheackIds.push(selectRecords[i].id)
               }
                let bStr = '结转';
                if (!b){
                    bStr = '反结转';
               }
                if (selectRecords.length > 0) {
                    this.$XModal.confirm('您确定要'+bStr+'该数据?').then(type => {
                        if (type === 'confirm') {
                            //执行删除请求
                            this.$axios({
                                method: 'post',
                                url: '/admin/inventory/setIsOver',
                                params: {
                                    cheackIds: this.cheackIds,
                                    b:b
                               }
                           }).then((response) => {          //这里使用了ES6的语法
                                //(response)       //请求成功返回的数据
                                if (response.status === 200 && response.data.state == 'ok') {
                                    this.$message({
                                        showClose: true,
                                        message: '批量结转成功',
                                        type: 'success'
                                   });

                                    this.search();
                               } else {
                                    this.$message({
                                        showClose: true,
                                        message: response.data.msg,
                                        type: 'error'
                                   });
                                    return
                               }
                           }).catch((error) => {
                                console.log(error)
                           });
                       }
                   })
               } else {
                    this.$message({
                        showClose: true,
                        message: "请先选择",
                        type: 'error'
                   });
               }
           },
            setIsLock(b){
                const $grid = this.$refs.xGrid
                const selectRecords = $grid.getCheckboxRecords();
                this.cheackIds = [];
                for (let i = 0; i < selectRecords.length; i++) {
                    this.cheackIds.push(selectRecords[i].id)
                }
                let bStr = '锁定';
                if (!b){
                    bStr = '反锁定';
                }
                if (selectRecords.length > 0) {
                    this.$XModal.confirm('您确定要'+bStr+'该数据?').then(type => {
                        if (type === 'confirm') {
                            //执行删除请求
                            this.$axios({
                                method: 'post',
                                url: '/admin/inventory/setIsLock',
                                params: {
                                    cheackIds: this.cheackIds,
                                    b:b
                                }
                            }).then((response) => {          //这里使用了ES6的语法
                                //(response)       //请求成功返回的数据
                                if (response.status === 200 && response.data.state == 'ok') {
                                    this.$message({
                                        showClose: true,
                                        message: '批量设置成功',
                                        type: 'success'
                                    });

                                    this.search();
                                } else {
                                    this.$message({
                                        showClose: true,
                                        message: response.data.msg,
                                        type: 'error'
                                    });
                                    return
                                }
                            }).catch((error) => {
                                console.log(error)
                            });
                        }
                    })
                } else {
                    this.$message({
                        showClose: true,
                        message: "请先选择",
                        type: 'error'
                    });
                }
            },
            deleteEvent() {
                const $grid = this.$refs.xGrid
                const selectRecords = $grid.getCheckboxRecords();
                this.cheackIds = [];
                for (let i = 0; i < selectRecords.length; i++) {
                    this.cheackIds.push(selectRecords[i].id)
               }
                if (selectRecords.length > 0) {
                    this.$XModal.confirm('您确定要删除该数据?').then(type => {
                        if (type === 'confirm') {
                            //执行删除请求
                            this.$axios({
                                method: 'post',
                                url: '/admin/inventory/deleteBatchVue',
                                params: {
                                    cheackIds: this.cheackIds
                               }
                           }).then((response) => {          //这里使用了ES6的语法
                                //(response)       //请求成功返回的数据
                                if (response.status === 200 && response.data.state == 'ok') {
                                    this.$message({
                                        showClose: true,
                                        message: '批量删除成功',
                                        type: 'success'
                                   });
                                    this.search();
                               } else {
                                    this.$message({
                                        showClose: true,
                                        message: response.data.msg,
                                        type: 'error'
                                   });
                                    return
                               }
                           }).catch((error) => {
                                console.log(error)
                           });
                       }
                   })
               } else {
                    this.$message({
                        showClose: true,
                        message: "请先选择",
                        type: 'error'
                   });
               }

           },

            editMethod({row, column}) {
                const $table = this.$refs.xGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
       },
        created() {
            this.search();
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }
</style>
